<template>
  <form @submit.prevent.stop="save" class="row q-gutter-lg" v-if="formFields">
    <div class="col">
      <div class="q-mb-sm">
        <h6 class="title-h6 q-mt-none">{{$t('processes.agentEntiteLie')}}</h6>
        <div class="row">
          <div v-if="linkedAgent">
            <AgentCard class="col-6" icon="accessibility" :showSeparator="false" v-if="typeLinkedObj.id === 'agent'" :agent="linkedAgent" @menuClick="onMenuClick" />
            <EntityCard class="col-6" icon="accessibility" :showSeparator="false" v-if="typeLinkedObj.id === 'entity'" :model="linkedAgent" @menuClick="onMenuClick" />
          </div>
          <div class="add-agent-card col-6" v-else>
            <div v-if="searchBoxActivated">
              <base-input-select :options="[{ id: 'agent', label: 'Agent' }, { id: 'entity', label: 'Entity' }]" :color="color" label="Type"
                v-model="typeLinkedObj" ref="qualification" />
              <search-box v-if="typeLinkedObj"
                @input="onSelectLinkedObj"
                :model_type="typeLinkedObj.id"
                :label="$t(`processes.fields.${typeLinkedObj.id}.label`)"
                :params="{ 'with_virtual_objects': true, 'with_deleted_objects': true }"
                :color="color" />
            </div>
            <base-add-button v-else :label="$t('processes.add_agent')" :color="color" @click="searchBoxActivated = true" />
          </div>
        </div>
      </div>
      <div>
        <h6 class="title-h6">{{$t('processes.linked_wallet')}}</h6>
        <div class="row q-col-gutter-md q-mb-md">
          <add-a-wallet :max="1" v-if="currentSuiviDeMandatGestionProcess && currentSuiviDeMandatGestionProcess.wallets" @walletsUpdated="updateWallet" v-model="currentSuiviDeMandatGestionProcess.wallets" :wallets="currentSuiviDeMandatGestionProcess.wallets" class="col"/>
          <add-a-wallet :max="1" v-else @walletsUpdated="updateWallet" class="col"/>
        </div>
      </div>
      <div class="bloc-item q-mb-sm q-mt-md">
        <h6 class="title-h6">{{$t('processes.motifFinaliteMandat')}}</h6>
        <div class="toggler-collapse" @click="toggleCollapsible">
          <div class="arrow"></div>
        </div>
        <div class="collapsible-div">
          <q-card flat bordered class="q-pa-sm q-mb-md">
            <q-card-section class="q-gutter-sm justify-center">
              <div class="row q-gutter-sm">
                <base-input-text class="col" id="motif" :label="$t('processes.fields.motif_mandat_gestion.label')" :color="color"
                  v-model="formData.motif" ref="label" />
                <base-input-text class="col" v-bind="formInputProps('issue')" :color="color"
                  v-model="formData.issue" ref="label" />
              </div>
            </q-card-section>
          </q-card>
          <q-card flat bordered class="q-pa-sm">
            <q-card-section class="justify-center">
              <div class="row">
                <base-input-text class="col-6" v-bind="formInputProps('part')" :color="color"
                  v-model="formData.part" ref="label" />
              </div>
              <div class="row q-gutter-sm">
                <base-input-date class="col" v-bind="formInputProps('date_debut')" :color="color"
                  v-model="formData.date_debut" ref="label" />
                <base-input-date class="col" v-bind="formInputProps('date_fin')" :color="color"
                  v-model="formData.date_fin" ref="label" />
              </div>
            </q-card-section>
          </q-card>
        </div>
      </div>
      <div class="bloc-item q-mb-sm q-mt-md">
        <h6 class="title-h6">{{$t('processes.suivi_du_mandat')}}</h6>
        <div class="toggler-collapse" @click="toggleCollapsible">
          <div class="arrow"></div>
        </div>
        <div class="collapsible-div">
          <q-card flat bordered class="q-pa-sm q-mb-md">
            <q-card-section class="justify-center">
              <div class="row">
                <div class="col-6">
                  <base-input-date class="col" v-bind="formInputProps('date_signature_mandat')" :color="color"
                    v-model="formData.date_signature_mandat" ref="label" />
                </div>
              </div>
              <div class="row q-gutter-sm q-mt-sm" v-for="(prorogation, index) in relatedProrogations" :key="prorogation.id">
                <base-input-date class="col" :label="$t('processes.fields.date_prorogation_mandat.label')" :color="color"
                  v-model="prorogation.date_prorogation_mandat" />
                <base-input-date class="col" :label="$t('processes.fields.date_rappel.label')" :color="color"
                  v-model="prorogation.date_rappel" />
                <div class="col-2 q-pl-sm column justify-center">
                  <q-btn class="" color="process" size="sm" @click="addProrogation(index)" :disabled="!isUpdate">{{$t('processes.save')}}</q-btn>
                </div>
              </div>
            </q-card-section>
          </q-card>
          <q-card flat bordered class="q-pa-sm">
            <q-card-section class="justify-center">
              <div class="row q-gutter-sm">
                <base-input-date class="col" :label="$t('processes.fields.date_prorogation_mandat.label')" :color="color"
                  v-model="dateProrogationMandat" />
                <base-input-date class="col" :label="$t('processes.fields.date_rappel.label')" :color="color"
                  v-model="dateRappelMandat" />
              </div>
              <div class="row q-mt-md justify-end">
                <div class="col col-3 q-py-sm q-px-md">
                  <q-btn class="full-width" color="process" size="sm" @click="addProrogation()" :disabled="!isUpdate">{{$t('processes.enregistrer')}}</q-btn>
                </div>
              </div>
            </q-card-section>
          </q-card>
        </div>
      </div>
      <div class="bloc-item q-mb-sm q-mt-md">
        <h6 class="title-h6">{{$t('processes.donneesChiffrees')}}</h6>
        <div class="toggler-collapse" @click="toggleCollapsible">
          <div class="arrow"></div>
        </div>
        <q-card flat bordered class="q-pa-sm collapsible-div">
          <q-card-section class="justify-center">
            <div class="row q-gutter-sm">
              <base-input-text class="col" v-bind="formInputProps('commission_yard')" :color="color"
                v-model="formData.commission_yard" ref="label" />
              <base-input-text class="col" v-bind="formInputProps('commission_sante')" :color="color"
                v-model="formData.commission_sante" ref="label" />
            </div>
            <div class="row q-gutter-sm">
              <base-input-text class="col" v-bind="formInputProps('commission_vie')" :color="color"
                v-model="formData.commission_vie" ref="label" />
              <base-input-text class="col" label="Montant Comm total" :readonly="true" placeholder="(Calcul automatique IARD + Vie + Santé)" :color="color"
                v-model="computedCommTotal" ref="label" />
            </div>
            <div class="row">
              <base-input-text class="col-6" v-bind="formInputProps('loyer')" :color="color"
                v-model="formData.loyer" ref="label" />
            </div>
          </q-card-section>
        </q-card>
      </div>
      <div class="bloc-item q-mb-sm q-mt-md">
        <h6 class="title-h6 process-color">{{$t('processes.echeancePaiement')}}</h6>
        <div class="toggler-collapse" @click="toggleCollapsible">
          <div class="arrow"></div>
        </div>
        <div class="collapsible-div">
          <q-card v-for="(echeancePaiement, index) in relatedEcheancePaiement" :key="echeancePaiement.id" flat bordered class="q-pa-sm q-mb-sm">
            <q-card-section class="justify-center">
              <div class="row q-gutter-sm">
                <base-input-text class="col" type="number" :label="$t('processes.fields.montant.label')" :color="color"
                    v-model="echeancePaiement.montant_echeance" />
                <base-input-date class="col" :label="$t('processes.fields.dateEcheance.label')" :color="color"
                    v-model="echeancePaiement.date_echeance" />
              </div>
              <div class="row q-gutter-sm">
                <div class="col q-mt-md">
                  <q-item-label class="">{{$t('processes.fields.paiementEffectue.label')}}</q-item-label>
                  <q-toggle class="" v-model="echeancePaiement.paiement_effectue" ref="is_red"
                    :color="color"
                  />
                </div>
                <base-input-date class="col" :label="$t('processes.fields.datePaiement.label')" :color="color"
                    v-model="echeancePaiement.date_paiement" />
              </div>
              <div class="row q-mt-md justify-end">
                <div class="col col-3 q-py-sm q-px-md">
                  <q-btn class="full-width" color="process" size="sm" @click="addEcheancePaiement(index)" :disabled="!isUpdate">{{$t('processes.save')}}</q-btn>
                </div>
              </div>
            </q-card-section>
          </q-card>
          <q-card flat bordered class="q-pa-sm q-mt-md">
            <q-card-section class="justify-center">
              <div class="row q-gutter-sm">
                <base-input-text class="col" type="number" :label="$t('processes.fields.montant.label')" :color="color"
                    value="" v-model="montant" />
                <base-input-date class="col" :label="$t('processes.fields.dateEcheance.label')" :color="color"
                    value="" v-model="dateEcheance" />
              </div>
              <div class="row q-gutter-sm">
                <div class="col q-mt-md">
                  <q-item-label class="">{{$t('processes.fields.paiementEffectue.label')}}</q-item-label>
                  <q-toggle class="" v-model="paiementEffectue" ref="is_red"
                    :color="color"
                  />
                </div>
                <base-input-date class="col" :label="$t('processes.fields.datePaiement.label')" :color="color"
                    v-model="datePaiement" />
              </div>
              <div class="row q-mt-md justify-end">
                <div class="col col-3 q-py-sm q-px-md">
                  <q-btn class="full-width" color="process" size="sm" @click="addEcheancePaiement()" :disabled="!isUpdate">{{$t('processes.enregistrer')}}</q-btn>
                </div>
              </div>
            </q-card-section>
          </q-card>
        </div>
      </div>

      <div class="bloc-item q-mb-sm">
        <h6 class="title-h6">{{$t('processes.linked_suivi_des_ic')}}</h6>
        <div class="toggler-collapse" @click="toggleCollapsible">
          <div class="arrow"></div>
        </div>
        <div class="collapsible-div row">
          <div v-if="relatedSuiviIc.length" class="row full-width q-col-gutter-md q-pt-sm">
            <div class="col-12" v-for="suiviDesIc in relatedSuiviIc" :key="suiviDesIc.id">
              <base-card @menuClick="onMenuClick" class="col-12" :actions="suiviDesIc.actions">
                <q-avatar slot='avatar' size="3rem" :color="color" text-color="white" icon="playlist_add_check" />
                <q-item-label @click="$router.push({ name: 'suivi-des-ic-form', params: { id: suiviDesIc.id } })" class="cursor-pointer q-mb-md"><strong>{{$t('processes.suivi_des_ic')}}</strong></q-item-label>
                <div class="row">
                  <div class="col" v-for="obj in suiviDesIc.wallets" :key="obj.id">
                    <q-item class="q-pa-none">
                      <q-item-section avatar>
                        <q-avatar icon="account_balance_wallet" size="2rem" color="reseau" />
                      </q-item-section>
                      <q-item-section>
                        <q-item-label caption>{{obj.NOMPTF}}</q-item-label>
                      </q-item-section>
                    </q-item>
                  </div>
                </div>
                <div class="row">
                  <div class="col" v-for="obj in suiviDesIc.agents" :key="obj.id">
                    <q-item class="q-pa-none">
                      <q-item-section avatar>
                        <q-avatar icon="accessibility" size="2rem" color="reseau" />
                      </q-item-section>
                      <q-item-section>
                        <q-item-label caption>{{obj.TITULAIRE}}</q-item-label>
                      </q-item-section>
                    </q-item>
                  </div>
                </div>
              </base-card>
            </div>
          </div>
          <div v-else class="col-6" style="height:80px">
            <base-add-button :label="$t('processes.create_linked_suivi_des_ic')" :color="color" @click="createLinkedProcess('suiviIc')" />
          </div>
        </div>
      </div>
    </div>

    <div class="col col-2 q-gutter-y-sm">
      <q-list class="full-width">
        <q-item class="q-pa-none">
          <base-input-select v-bind="formInputProps('statut')" :color="color"
            v-model="formData.statut" ref="statut" />
      </q-item>
      </q-list>

      <div class="q-gutter-y-sm">
        <q-btn :color="color" class="full-width" size="sm" outline @click.native="destroy" :disabled="!isUpdate">{{$t('form.delete')}}</q-btn>
        <q-btn :color="color" class="full-width" size="sm" unelevated type="submit" :disabled="!hasChanged">{{$t('form.submit')}}</q-btn>
        <q-btn :color="color" class="full-width q-py-md" size="sm" @click="finishProcess">{{$t('form.finish')}}</q-btn>
      </div>

    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
import { BaseForm } from '../../../mixins'
import addAWallet from '../shared/addAWallet'
import { AgentCard } from '../../../components/agents'
import { EntityCard } from '../../../components/entities'

export default {
  mixins: [BaseForm],
  components: { addAWallet, AgentCard, EntityCard },
  props: {
    'form_model': { type: String, default: 'processes' },
    'action': { type: String, default: 'processes/saveProcesses' }
  },

  data () {
    return {
      filter: {},
      loading: false,
      isFormOpen: false,
      linkedAgent: null,
      searchBoxActivated: false,
      isUpdate: this.$route.params.id,
      booleanOption: [
        { value: true, label: 'Oui' },
        { value: false, label: 'Non' }
      ],
      // Related processes
      relatedProcessLinked: [],
      // Prorogation
      dateProrogationMandat: null,
      dateRappelMandat: null,
      relatedProrogations: [],
      // Montant échéance
      montant: null,
      dateEcheance: null,
      paiementEffectue: false,
      datePaiement: null,
      relatedEcheancePaiement: [],
      typeLinkedObj: null
    }
  },

  computed: {
    ...mapGetters({
      color: 'pages/getColor',
      isAdmin: 'auth/isAdmin',
      currentSuiviDeMandatGestionProcess: 'processes/getCurrentProcess',
      rowsPerPageOptions: 'config/getRowsPerPageOptions'
    }),

    computedCommTotal () {
      let total = parseInt(this.formData.commission_yard || 0) + parseInt(this.formData.commission_sante || 0) + parseInt(this.formData.commission_vie || 0)
      return total || null
    },

    relatedSuiviIc: {
      get () {
        return this.relatedProcessLinked.length ? this.relatedProcessLinked.filter(process => process.type === 'suiviIc') : []
      },
      set (newVal) {
        this.formData.related_objects = this.formData.related_objects.filter(relObj => relObj.model_type !== 'suiviIc')
        this.formData.related_objects.push(...newVal.map(suiviIc => ({ model_type: 'suiviIc', model_id: suiviIc.id })))
        this.relatedProcessLinked = this.relatedProcessLinked.filter(process => process.type !== 'suiviIc')
        this.relatedProcessLinked.push(...newVal)
      }
    }
  },

  watch: {
    formFields: {
      handler (newVal, oldVal) {
        this.formData.model_type = 'mandatGestion'
        if (!newVal || !this.currentSuiviDeMandatGestionProcess) return

        this.formData = {
          ...this.formData,
          ...newVal.map(({ id, options, type, boolean }) => ({ id, options, type, boolean }))
            .reduce((acc, { id, options, type, boolean }) => ({
              ...acc,
              [id]: options
                ? options.find(({ value }) => value === this.currentSuiviDeMandatGestionProcess[id])
                : type === 'select' && boolean
                  ? this.booleanOption.find(val => val.value === this.currentSuiviDeMandatGestionProcess[id])
                  : this.currentSuiviDeMandatGestionProcess[id]
            }), {})
        }

        this.formData.related_objects = []
        if (this.$route.params.id) {
          this.formData.id = this.$route.params.id

          // Set wallets data
          let walletsMapped = this.currentSuiviDeMandatGestionProcess.wallets.map(wallet => ({
            model_type: 'wallet',
            model_id: wallet.id
          }))
          this.formData.related_objects.push(...walletsMapped)

          // Set agent
          if (this.currentSuiviDeMandatGestionProcess.agents && this.currentSuiviDeMandatGestionProcess.agents.length) {
            this.typeLinkedObj = { id: 'agent', label: 'Agent' }
            this.linkedAgent = this.currentSuiviDeMandatGestionProcess.agents.map(agent => ({
              ...agent,
              label: agent['TITULAIRE'],
              actions: [{ label: this.$t('tasks.remove_linked_object'), payload: { name: 'model-detach', params: { id: agent.id } } }],
              is_favorite: !!this.favorites && !!this.favorites['agent'] &&
                this.favorites['agent'].some(favorite => favorite.id === agent.id),
              model: { model_type: 'agent', model_id: agent.id }
            }))[0]
          }
          // Set entity
          if (this.currentSuiviDeMandatGestionProcess.entities && this.currentSuiviDeMandatGestionProcess.entities.length) {
            this.typeLinkedObj = { id: 'entity', label: 'Entity' }
            this.linkedAgent = this.currentSuiviDeMandatGestionProcess.entities.map(entity => ({
              label: entity.label,
              id: entity.id,
              actions: [
                { label: this.$t('tasks.remove_linked_object'), payload: { name: 'model-detach', params: { id: entity.value } } }
              ]
            }))[0]
          }
          // Set process suivi IC lié
          if (this.currentSuiviDeMandatGestionProcess.suivi_ics) {
            let suiviIcFormDataMapped = this.currentSuiviDeMandatGestionProcess.suivi_ics.map(suiviIc => ({
              model_type: 'suiviIc',
              model_id: suiviIc.id
            }))
            this.formData.related_objects.push(...suiviIcFormDataMapped)

            let suiviIcLinkedMapped = this.currentSuiviDeMandatGestionProcess.suivi_ics.map(suiviIc => ({
              type: 'suiviIc',
              id: suiviIc.id,
              wallets: suiviIc.wallets,
              agents: suiviIc.agents,
              actions: [
                { label: this.$t('processes.unlink_suiviIc'), payload: { name: 'unlink-suiviIc', params: { id: suiviIc.id } } }
              ]
            }))
            this.relatedProcessLinked.push(...suiviIcLinkedMapped)
          }
          // Set prorogation
          if (this.currentSuiviDeMandatGestionProcess.prorogations) {
            this.relatedProrogations = this.currentSuiviDeMandatGestionProcess.prorogations
          }
          // Set échéances et paiement
          if (this.currentSuiviDeMandatGestionProcess.echeance_paiements) {
            this.relatedEcheancePaiement = this.currentSuiviDeMandatGestionProcess.echeance_paiements
          }
        }
      },
      immediate: true
    },
    linkedAgent (newVal, oldVal) {
      let model_type = this.typeLinkedObj.id === 'agent' ? 'agent' : 'entities'
      if (!this.formData.related_objects) this.formData.related_objects = []
      this.formData.related_objects = this.formData.related_objects.filter(related => related.model_type !== 'agent').filter(related => related.model_type !== 'entities')
      if (newVal) this.formData.related_objects.push({ model_type: model_type, model_id: newVal.id })
    }
  },

  mounted () {
    this.$store.commit('pages/setBreadcrumbs', [
      { label: this.$t('global.home'), to: { name: 'home' }, icon: 'home' },
      { label: this.$t('processes.processAgence'), to: { name: '' } },
      { label: this.$t('processes.title.suivi_de_mandat_gestion'), to: { name: 'suivi-de-mandat-gestion-en-cours' } },
      this.$route.params.id ? { label: `fiche #${this.$route.params.id}`, to: { name: 'suivi-de-mandat-gestion-form', params: { id: this.$route.params.id } } } : {}
    ])
    this.$store.commit('pages/setPageTitle', this.$t('processes.title.suivi_de_mandat_gestion'))

    this.$store.commit('pages/setTabs', [
      { name: 'suivi-de-mandat-gestion-form', label: 'Informations', to: { name: 'suivi-de-mandat-gestion-form' }, params: { id: this.$route.params.id } }
    ])
    if (this.$route.params.id) {
      this.formData.id = this.$route.params.id
    }
  },

  preFetch ({ store, currentRoute, previousRoute, redirect, ssrContext }) {
    if (currentRoute.params.id) {
      store.dispatch('processes/fetchProcess', { id: currentRoute.params.id, type: 'mandatGestion' }).then(() => {
        store.dispatch('processes/fetchFormFields', 'mandatGestion')
      })
    } else {
      store.dispatch('processes/fetchFormFields', 'mandatGestion')
    }
  },
  beforeDestroy () {
    this.$store.commit('processes/setCurrentProcess', null)
  },
  methods: {
    addProrogation (index = null) {
      let dataToSend = {}
      if (index !== null) {
        dataToSend = {
          id: this.relatedProrogations[index].id,
          mandat_gestion_id: this.$route.params.id,
          date_prorogation_mandat: this.relatedProrogations[index].date_prorogation_mandat,
          date_rappel: this.relatedProrogations[index].date_rappel
        }
      } else {
        dataToSend = {
          mandat_gestion_id: this.$route.params.id,
          date_prorogation_mandat: this.dateProrogationMandat,
          date_rappel: this.dateRappelMandat
        }
      }
      this.$store.dispatch('processes/addProrogation', dataToSend).then(data => {
        if (index === null) {
          this.relatedProrogations.push({ id: data.id, date_prorogation_mandat: this.dateProrogationMandat, date_rappel: this.dateRappelMandat })
          this.dateProrogationMandat = null
          this.dateRappelMandat = null
        }
        this.notifySuccess('processes.prorogationAdded')
      })
    },
    addEcheancePaiement (index = null) {
      let dataToSend = {}
      if (index !== null) {
        dataToSend = {
          id: this.relatedEcheancePaiement[index].id,
          date_echeance: this.relatedEcheancePaiement[index].date_echeance,
          date_paiement: this.relatedEcheancePaiement[index].date_paiement,
          montant_echeance: this.relatedEcheancePaiement[index].montant_echeance,
          paiement_effectue: this.relatedEcheancePaiement[index].paiement_effectue,
          mandat_gestion_id: this.$route.params.id
        }
      } else {
        dataToSend = {
          date_echeance: this.dateEcheance,
          date_paiement: this.datePaiement,
          montant_echeance: this.montant,
          paiement_effectue: this.paiementEffectue,
          mandat_gestion_id: this.$route.params.id
        }
      }

      this.$store.dispatch('processes/addEcheancePaiement', dataToSend).then(data => {
        if (index === null) {
          this.relatedEcheancePaiement.push({ id: data.id, ...dataToSend })
          this.dateEcheance = null
          this.datePaiement = null
          this.montant = null
          this.paiementEffectue = null
        }
        this.notifySuccess('processes.echeancePaiementAdded')
      })
    },
    createLinkedProcess (process) {
      this.$store.dispatch('processes/createLinkedProcess', { linkedProcess_type: process, process_id: this.$route.params.id, process: 'mandatGestion' }).then(datas => {
        if (!this.formData.related_objects) this.formData.related_objects = []
        datas.forEach(linkedObj => {
          console.log(linkedObj)
          this.relatedProcessLinked.push({
            id: linkedObj.id,
            type: process,
            wallets: linkedObj.wallets,
            agents: linkedObj.agents,
            actions: [
              { label: this.$t(`processes.unlink_${process}`), payload: { name: `unlink-${process}`, params: { id: linkedObj.id } } }
            ]
          })
          this.formData.related_objects.push({
            model_type: process,
            model_id: linkedObj.id
          })
        })
      })
    },
    finishProcess () {
      this.formData.statut = { label: 'TERMINE', value: 'TERMINE' }
      this.save()
    },
    toggleCollapsible (event) {
      let originalElement = event.target || event.srcElement || event.originalTarget
      if (originalElement.classList.contains('arrow')) {
        originalElement.parentElement.classList.toggle('rotate')
        originalElement.parentElement.nextSibling.classList.toggle('display-none')
      } else {
        originalElement.classList.toggle('rotate')
        originalElement.nextSibling.classList.toggle('display-none')
      }
    },
    onSave ({ id }) {
      this.isUpdate = true
      if (!this.$route.params.id) this.$router.push({ name: 'suivi-de-mandat-gestion-form', params: { id } })
    },
    copyMandat () {
      const text = 'Ito ny texte'
      let el = document.createElement('textarea')
      el.value = text
      el.setAttribute('readonly', '')
      el.style.position = 'absolute'
      el.style.left = '-9999px'
      document.body.appendChild(el)
      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)
    },
    updateWallet (wallets) {
      console.log(wallets)
      let walletMapped = wallets.map(wallet => ({
        model_type: 'wallet',
        model_id: wallet.id
      }))

      if (!this.formData.related_objects) this.formData.related_objects = []
      // Remove all wallet in related object
      this.formData.related_objects = this.formData.related_objects.filter(related => related.model_type !== 'wallet')
      this.formData.related_objects.push(...walletMapped)
    },
    onSelectLinkedObj (obj) {
      this.linkedAgent = {
        label: obj.value.label,
        id: obj.value.value,
        actions: [
          { label: this.$t('tasks.remove_linked_object'), payload: { name: 'model-detach', params: { id: obj.value.value } } }
        ]
      }
    },
    onMenuClick ({ name, params }) {
      this.hasChanged = true
      if (name === 'model-detach') {
        this.linkedAgent = null
        this.searchBoxActivated = false
      } else if (name === 'delete-link') {
        this.relatedLinks = this.relatedLinks.filter(link => link.id !== params.id)
      } else if (name === 'sujet-detach') {
        this.sujets = this.sujets.filter(sujet => sujet.id !== params.id)
      } else if (name === 'unlink-recrutement') {
        this.relatedSuiviDesIc = this.relatedSuiviDesIc.filter(suiviDesIc => suiviDesIc.id !== params.id)
      } else if (name === 'unlink-suiviIc') {
        this.relatedSuiviIc = this.relatedSuiviIc.filter(suiviIc => suiviIc.id !== params.id)
      }
    },

    destroy () {
      this.$confirm(this.$t('processes.delete_process_confirm'), this.$t('form.delete')).onOk(() => {
        this.pending = true
        this.$store.dispatch('processes/deleteProcesses', { type: 'mandatGestion', id: this.$route.params.id }).then(() => {
          this.notifySuccess('processes.delete_success')
          this.$router.go(-1)
        }).catch(this.$notifyError)
          .finally(() => { this.pending = false })
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
  .process-color
    color $process
  .bloc-item
    position relative
  .toggler-collapse
    border 1px solid $process
    border-radius 100%
    width 20px
    height 20px
    position absolute
    right 0;
    top 7px;
    cursor pointer
  .arrow
    border-top 1px solid $process
    border-left 1px solid $process
    transform rotate(45deg)
    width 6px
    height 6px
    margin-left 6px
    margin-top 7px
  .display-none
    display none
  .rotate
    transform rotate(180deg)
</style>
